<script setup lang="ts">
import { AntlerClasses, installAntlerComponent } from "@/Utils/component";

export type PageAs = "div" | "main";
export type PageSize = "small" | "regular";
export type PageVariant = "centered" | "noBottom" | "noTop";

type Props = {
    as?: PageAs;
    size?: PageSize;
    variant?: PageVariant;
};
const {
    as = "div",
    size = "regular",
    variant = undefined,
} = defineProps<Props>();

// classes
const classes: AntlerClasses<Props> = {
    base: "flex flex-col w-full grow",
    variants: {
        size: {
            small: "mt-8 mb-16",
            regular: "mt-8 mb-8 sm:mt-16 sm:mb-16",
        },
        variant: {
            centered: "items-center justify-center",
            noBottom: "mb-0",
            noTop: "mt-0",
        },
    },
};
const { aClass } = installAntlerComponent("page", { size, variant }, classes);
</script>

<template>
    <component
        :is="as"
        :class="aClass()"
    >
        <slot />
    </component>
</template>
